
const addOrReplaceItemInArray = (array: any, element: any, idPropertyName: string) => {
    if (!idPropertyName)
        idPropertyName = "Id";
    const indexOfelement = array.findIndex((item: any) => { return element[idPropertyName] === item[idPropertyName] });
    if (indexOfelement == -1)
        array.push(element);
    else
        array[indexOfelement] = element;
}
const removeItemFromArray = (array: any, element: any, idPropertyName: string) => {
    if (!idPropertyName)
        idPropertyName = "Id";
    const indexOfelement = array.findIndex((item: any) => { return element[idPropertyName] === item[idPropertyName] });
    if (indexOfelement >= 0)
        array.splice(indexOfelement, 1);
}

export {
    addOrReplaceItemInArray,
    removeItemFromArray
}