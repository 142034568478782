<template>
    <div class="position-fixed bottom-0 right-0 p-3" style="z-index: 1000; right: 0; bottom: 0;">
        <div id="toastGeneric" ref="toastRef" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
            <div class="toast-header d-flex">
                <strong class="mr-auto">{{title}}</strong>
                <button type="button" class="btn btn-sm btn-light ms-auto close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">
                <div v-if="success" class="d-flex">
                    <i class="fa-regular fa-circle-check me-2 d-flex align-items-center" style="font-size: 1.2rem; color: #4aad00"></i>
                    <span v-if="message == ''"><b>Saved Successfully.</b></span>
                    <span v-else><b>{{message}}</b></span>
                </div>
                <div v-else class="d-flex">
                    <i class="fa-regular fa-circle-xmark me-2 d-flex align-items-center" style="font-size: 1.2rem; color: #dc3545;"></i>
                    <span v-if="message == ''"><b>Failed to save, please try again.</b></span>
                    <span v-else><b>{{message}}</b></span>
                </div>
            </div>
        </div>
    </div>s
</template>

<script setup>
    import { defineProps, onMounted, ref, watch } from 'vue';
    import { Toast } from "bootstrap";
    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        success: Boolean,
        show: Boolean,
        message: {
            type: String,
            default: ''
        },
    });

    watch(() => props.show,
        () => {
            toast.value.show();
        })

    const toastRef = ref();
    const toast = ref();

    onMounted(() => {
        toast.value = new Toast(toastRef.value);
    })
</script>
