type RowType = {
    confidenceLevel?: {
        name?: string
    },
    status?: {
        name?: string
    },
    price: number,
    currency?: {
        symbol: string,
        isoCode?: string
    },
    classification?: {
        name?: string,
        shortName?: string
    },
    country?: {
        name?: string,
        isoCode?: string
    }
};

type SvgTemplatesType = {
    [key: number]: string;
};

const svgTemplates: SvgTemplatesType = {
    1: `<svg width="40" height="20" viewBox="0 0 455 233" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2852 165.64V214.147" stroke="#EC5943" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M80.2852 132.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M140.2852 99.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M200.2852 66.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M260.2852 33.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>`,
    2: `<svg width="40" height="20" viewBox="0 0 455 233" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2852 165.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M80.2852 132.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M140.2852 99.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M200.2852 66.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M260.2852 33.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>`,
    3: `<svg width="40" height="20" viewBox="0 0 455 233" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2852 165.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M80.2852 132.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M140.2852 99.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M200.2852 66.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M260.2852 33.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>`,
    4: `<svg width="40" height="20" viewBox="0 0 455 233" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2852 165.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M80.2852 132.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M140.2852 99.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M200.2852 66.64V214.147" stroke="#EF8300" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M260.2852 33.64V214.147" stroke="#E9ECEF" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
    5: `<svg width="40" height="20" viewBox="0 0 455 233" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2852 165.64V214.147" stroke="#28a745" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M80.2852 132.64V214.147" stroke="#28a745" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M140.2852 99.64V214.147" stroke="#28a745" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M200.2852 66.64V214.147" stroke="#28a745" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M260.2852 33.64V214.147" stroke="#28a745" stroke-width="36.547" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
};
export const getConfidentLevelIcon = (data: unknown, type: string, row: RowType) => {
    //if (type !== 'display') {
    //    return '';
    //}
    //const confidenceLevel = row?.confidenceLevel?.name || ''
    //const confidenceGrade = Math.ceil(Number(confidenceLevel.split('%')[0]) / 20) || 1;
    //return `<span title="${confidenceLevel}">${svgTemplates[confidenceGrade] || ''}</span>`;
};

export const isValidDate = (value: string) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
}

export const getStatusTemplate = (data: unknown, type: string, row: RowType) => {
    const status = row?.status?.name || '--';
    return `<span class="${status?.toLowerCase()}-highlight">${status}</span>`;
}


export const getCountryWithFlag = (data: unknown, type: string, row: RowType) => {
    if (type !== 'display') {
        return '';
    }
    const country = row?.country?.name || '--';
    const countryCode = row.country?.isoCode?.toLowerCase() || '';
    return `<span class="fi fi-${countryCode}"></span> ${country}`;
}

export function parseError(error: unknown) {
    if (typeof error === 'string') {
        return { message: error };
    } else if (error instanceof Error) {
        return { message: error.message };
    } else if (typeof error === 'object' && error !== null && 'message' in error) {
        return error;
    } else {
        return { message: 'An unknown error occurred' };
    }
}

export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
}