import {defineStore} from 'pinia';

import {fetchWrapper, downloadWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}customers`;

export const useCustomersStore = defineStore({
    id: 'customers',
    state: () => ({
        customers: [],
        filters: {},
        error: null,
        skip: 0,  
        take: 100,
        hasMore: true
    }),
    actions: {
        setFilters(filters) {
            this.filters = filters;
        },
        async fetchFirst() {
            this.skip = 0;
            try {
                const newCustomers = await fetchWrapper.post(`${baseUrl}/dto/${this.skip}/${this.take}`, this.filters);
                this.customers = newCustomers;
                this.skip += this.take;
                this.hasMore = newCustomers.length > 0;
            } catch (error) {
                console.log('Error fetching first batch of customers:', error);
                this.error = error;
            }
        },
        async fetchNext() {
            try {
                const newCustomers = await fetchWrapper.post(`${baseUrl}/dto/${this.skip}/${this.take}`, this.filters);
                this.customers = [...this.customers, ...newCustomers];
                this.skip += this.take;
                this.hasMore = newCustomers.length > 0;
            } catch (error) {
                console.log('Error fetching next batch of customers:', error);
                this.error = error;
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching customers:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create customers:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update customers:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                // check if customer participates in any projects as customer or assembler
                const check = await this.check(id);
                if (check) {
                    this.error = 'Customer participates in projects';
                    throw new Error('Customer participates in projects');
                }
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete customers:', error);
                throw parseError(error);
            }
        },
        async check (id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/check/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error check customers:', error);
                throw parseError(error);
            }
        },
        async search (search) {
            try {
                this.customers = [];
                this.customers = await fetchWrapper.get(`${baseUrl}/search/${search}`);
            } catch (error) {
                this.error = error;
                console.error('Error search customers:', error);
                throw parseError(error);
            }
        },
        logout() {
            this.customers = [];
            this.filters = {};
            this.error = null;
            this.skip = 0;
            this.take = 100;
            this.hasMore = true;
        },
        exportToExcel() {
            return downloadWrapper.post(`${baseUrl}/export`, this.filters);
        }
    }
});
