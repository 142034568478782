<script setup>
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';

    import { useAuthStore } from '@/stores';

    const schema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    function onSubmit(values, { setErrors }) {
        const authStore = useAuthStore();
        const { username, password } = values;

        return authStore.login(username, password)
            .catch(error => setErrors({ apiError: error }));
    }

    //function microsoftLogin() {
    // const authStore = useAuthStore();
    // authStore.microsoftLogin();
    //}

    //function linkedinLogin() {
    // const authStore = useAuthStore();
    // authStore.linkedinLogin();
    //}
</script>

<template>
    <div class="background-image">
        <div class="d-flex align-items-center justify-content-center" style="height: 75vh;">
            <div class="card" style="width: 400px; height: 350px;">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center">
                        <img src="/logo.png" alt="Logo" height="40">
                    </div>
                    <br />
                    <h4 class="text-center">Login</h4>
                    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
                        <div class="form-group mb-2">
                            <label class="form-label">Username</label>
                            <Field name="username" type="text" class="form-control" :class="{ 'is-invalid': errors.username }" />
                            <div class="invalid-feedback">{{errors.username}}</div>
                        </div>
                        <div class="form-group mb-2">
                            <label class="form-label">Password</label>
                            <Field name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.password }" />
                            <div class="invalid-feedback">{{errors.password}}</div>
                        </div>
                        <div class="form-group mb-2" style="margin-top: 20px">
                            <button class="btn btn-primary" :disabled="isSubmitting">
                                <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
                                Login
                            </button>
                        </div>
                        <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
   
</template>

<style scoped>
    .login-service-icon {
        height: 40px;
        margin-right: 10px;
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../public/mornsun-power-bg.png'); 
        background-size: cover;
        background-position: center; 
        opacity: 1;
    }
</style>
