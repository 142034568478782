import {defineStore} from 'pinia';
import {fetchWrapper, uploadWrapper, downloadWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}projects`;

export const useProjectsStore = defineStore({
    id: 'projects',
    state: () => ({
        projects: [],
        filters: {},
        error: null,
        skip: 0,
        take: 100,
        hasMore: true,
        setValues: null
    }),
    actions: {
        setFilters(filters) {
            this.filters = filters;
        },
        async fetchFirst() {
            this.skip = 0;
            try {
                const newProjects = await fetchWrapper.post(`${baseUrl}/dto/${this.skip}/${this.take}`, this.filters);
                this.projects = newProjects;
                this.skip += this.take;
                this.hasMore = newProjects.length > 0;
            } catch (error) {
                console.log('Error fetching first batch of projects:', error);
                this.error = error;
            }
        },
        async fetchNext() {
            try {
                const newProjects = await fetchWrapper.post(`${baseUrl}/dto/${this.skip}/${this.take}`, this.filters);
                this.projects = [...this.projects, ...newProjects];
                this.skip += this.take;
                this.hasMore = newProjects.length > 0;
            } catch (error) {
                console.log('Error fetching next batch of projects:', error);
                this.error = error;
            }
        },
        async getById(id) {
            try {
                const project = await fetchWrapper.get(`${baseUrl}/${id}`);
                return project;
            } catch (error) {
                console.error('Error fetching project:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create project:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update project:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete project:', error);
                throw parseError(error);
            }
        },
        async upload(formData) {
            try {
                return await uploadWrapper.post(`${baseUrl}/upload`, formData);
            } catch (error) {
                this.error = error;
                console.error('Error upload file:', error);
                throw parseError(error);
            }
        },
       logout() {
            this.projects = [];
            this.filters = {};
            this.error = null;
            this.skip = 0;
            this.take = 100;
            this.hasMore = true;
            this.setValues = null;
        },
        exportToExcel() {
           return downloadWrapper.post(`${baseUrl}/export`, this.filters);
        }
    }
});
