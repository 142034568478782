import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}project-parts`;

export const useProjectPartsStore = defineStore({
    id: 'projectParts',
    state: () => ({
        projectParts: [],
        error: null
    }),
    actions: {
        async getAll() {
            this.projectParts = [];
            try {
                this.projectParts = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching Project Parts:', error)
                this.projectParts = {error};
            }
        },
        async getById(id) {
            try {
                const projectpart = await fetchWrapper.get(`${baseUrl}/${id}`);
                return projectpart;
            } catch (error) {
                console.error('Error fetching Project Parts:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create Project Parts:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update Project Parts:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete Project Parts:', error);
                throw parseError(error);
            }
        }
    }
});
