import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}project-parts-units`;

export const useProjectPartUnitsStore = defineStore({
    id: 'projectPartUnits',
    state: () => ({
        projectPartUnits: []
    }),
    actions: {
        async getAll() {
            this.projectPartUnits = [];
            try {
                this.projectPartUnits = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching project part Units:', error)
                this.projectPartUnits = {error};
            }
        }
    }
});
