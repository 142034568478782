import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}abstract-contact-persons`;

export const useAbstractContactPersonStore = defineStore({
    id: 'abstractContactPersons',
    state: () => ({
        abstractContactPersons: [],
        error: null
    }),
    actions: {
        async getAll() {
            this.abstractContactPersons = [];
            try {
                const result = await fetchWrapper.get(`${baseUrl}/all`);
                this.abstractContactPersons = result;
                return result;
            } catch (error) {
                console.log('Error fetching Abstract Contact Persons:', error)
                this.abstractContactPersons = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching Abstract Contact Person:', error);
                throw parseError(error);
            }
        },
        async getBySearch(search) {
            try {
                return await fetchWrapper.get(`${baseUrl}/search/${search}`);
            } catch (error) {
                console.error('Error fetching Abstract Contact Person:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create Abstract Contact Person:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update Abstract Contact Person:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete Abstract Contact Person:', error);
                throw parseError(error);
            }
        }
    }
});
