import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}project-part-confidence-levels`;

export const useProjectPartConfidenceLevelsStore = defineStore({
    id: 'projectPartConfidenceLevels',
    state: () => ({
        projectPartConfidenceLevels: []
    }),
    actions: {
        async getAll() {
            this.projectPartConfidenceLevels = [];
            try {
                this.projectPartConfidenceLevels = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching project part Confidence Levels:', error)
                this.projectPartConfidenceLevels = {error};
            }
        }
    }
});
