import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}distributor-field-application-engineers`;

export const useDistributorFieldApplicationEngineersStore = defineStore({
    id: 'distributorFieldApplicationEngineers',
    state: () => ({
        distributorFieldApplicationEngineers: [],
        error: null
    }),
    actions: {
        async getForDistributor(DistributorId) {
            this.distributorFieldApplicationEngineers = [];
            try {
                this.distributorFieldApplicationEngineers = await fetchWrapper.get(`${baseUrl}/for-distributor/${DistributorId}`);
            } catch (error) {
                console.log('Error fetching distributor field application engineers:', error)
                this.distributorFieldApplicationEngineers = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching distributor field application engineers:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create distributor field application engineers:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update distributor field application engineers:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete distributor field application engineers:', error);
                throw parseError(error);
            }
        }
    }
});
