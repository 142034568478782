import {defineStore} from 'pinia';
import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}user-filters`;

export const useUserFiltersStore = defineStore({
    id: 'userFilters',
    state: () => ({
        userFilters: []
    }),
    actions: {
        async loadFilters(userId, filterType) {
            this.userFilters = [];
            try {
                const result = await fetchWrapper.get(`${baseUrl}/${userId}/${filterType}`);
                this.userFilters = result;
            } catch (error) {
                this.error = error;
                console.error('Error load user filters:', error);
                throw parseError(error);
            }
        },
        async saveFilter(userId, filterId, filterType, filterName, filterValues) {
            try {
                console.log(filterValues)
                const result = await fetchWrapper.post(`${baseUrl}/${userId}/${filterId}/${filterType}/${filterName}`, JSON.stringify(filterValues));
                return result;
            } catch (error) {
                this.error = error;
                console.error('Error save user filter:', error);
                throw parseError(error);
            }
        },
        async renameFilter(filterId, newFilterName) {
            try {   
                await fetchWrapper.put(`${baseUrl}/${filterId}/${newFilterName}`);
            } catch (error) {
                this.error = error;
                console.error('Error rename user filter:', error);
                throw parseError(error);
            }
        },
        async deleteFilter(filterId) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${filterId}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete user filter:', error);
                throw parseError(error);
            }
        }
    }
});
