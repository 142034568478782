import { createRouter, createWebHistory } from 'vue-router'
import ProjectsList from './views/Lists/ProjectsList.vue';
import DistributorList from "@/views/Lists/DistributorList.vue";
import UserList from "@/views/Lists/UserList.vue";
import CustomerList from "@/views/Lists/CustomerList.vue";
import HomeView from "@/components/HomeView.vue";
import LoginView from "@/components/LoginView.vue";
import ProjectView from "@/views/ProjectView.vue";
import CustomerView from "@/views/CustomerView.vue";
import DistributorView from "@/views/DistributorView.vue";
import { useAuthStore, useTabsStore } from '@/stores';
import UserView from "@/views/UserView.vue";

const routes = [
    { path: '/', component: HomeView, meta: { title: 'Home' } },
    { path: '/login', component: LoginView, meta: { title: 'Login' } },
    { path: '/projects', component: ProjectsList, meta: { title: 'Projects' } },
    { path: '/projects/:projectId', component: ProjectView, meta: { title: 'Project Detail' } },
    { path: '/customers', component: CustomerList, meta: { title: 'Customers' } },
    { path: '/customers/:customerId', component: CustomerView, meta: { title: 'Customer Detail' } },
    { path: '/distributors', component: DistributorList, meta: { title: 'Distributors' } },
    { path: '/distributors/:distributorId', component: DistributorView, meta: { title: 'Distributor Detail' } },
    { path: '/users', component: UserList, meta: { title: 'Users' } },
    { path: '/users/:userId', component: UserView, meta: { title: 'User Detail' } }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to) => {
    (window as any).document.title = to.meta.title || 'Mornsun'
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login']; //, '/'
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();
    const storeTabs = useTabsStore();
    storeTabs.addTab({ route: to.path });
    console.log(!auth.user)
    if (authRequired && !auth.user) {
        // get user profile use profile endpoint and JWT token from get parameter
        if (to.path === '/profile') {
            try {
                const jwtToken = to.query.token;
                await auth.fetchUserProfile(jwtToken);
                return '/';
            } catch (error) {
                console.error(error);
                return '/login';
            }
        } else {
            auth.returnUrl = to.fullPath;
            return '/login';
        }
    }
});

export default router

