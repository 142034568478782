import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}user-groups`;

export const useUserGroupsStore = defineStore({
    id: 'userGroups',
    state: () => ({
        userGroups: []
    }),
    actions: {
        async getAllByDto() {
            this.userGroups = [];
            try {
                this.userGroups = await fetchWrapper.get(`${baseUrl}/all/dto`);
            } catch (error) {
                this.userGroups = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching user group:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create user group:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update user group:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete user group:', error);
                throw parseError(error);
            }
        }
    }
});
