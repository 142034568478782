import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}project-part-classifications`;

export const useProjectPartClassificationsStore = defineStore({
    id: 'projectPartClassifications',
    state: () => ({
        projectPartClassifications: []
    }),
    actions: {
        async getAll() {
            this.projectPartClassifications = [];
            try {
                this.projectPartClassifications = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching project part Classifications:', error)
                this.projectPartClassifications = {error};
            }
        }
    }
});
