import { useAuthStore } from '@/stores';

export const downloadWrapper = {
    post: request('POST'),
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.token;
    const isApiUrl = url.startsWith(process.env.VUE_APP_API_URL);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

async function handleResponse(response) {
    try {
        if (!response.ok) {
            const {user, logout} = useAuthStore();
            if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }
            const error = response.statusText;
            return Promise.reject(error);
        }
        // get filename from content-disposition
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const filename = filenameRegex.exec(response.headers.get('content-disposition'))[1].replace(/"/g, '');
        const data = await response.blob().then((blob) => ({blob, filename}));
        // create blob link to download
        const url = window.URL.createObjectURL(new Blob([data.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.filename);
        // append to html and open
        document.body.appendChild(link);
        link.click();
        // cleanup
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log('Error downloading file:', error);
        throw error;
    }
}    
