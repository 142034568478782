import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}project-part-statuses`;

export const useProjectPartStatusesStore = defineStore({
    id: 'projectPartStatuses',
    state: () => ({
        projectPartStatuses: []
    }),
    actions: {
        async getAll() {
            this.projectPartStatuses = [];
            try {
                this.projectPartStatuses = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching project part Statuses:', error)
                this.projectPartStatuses = {error};
            }
        }
    }
});
