import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}currencies`;

export const useCurrenciesStore = defineStore({
    id: 'currencies',
    state: () => ({
        currencies: []
    }),
    actions: {
        async getAll() {
            this.currencies = [];
            try {
                this.currencies = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching Currencies:', error)
                this.currencies = {error};
            }
        }
    }
});
