<style scoped>
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
</style>

<template>
    <teleport to="body">
        <div class="overlay" v-if="show" @click="close"></div>
        <div class="modal" tabindex="-1" role="dialog" v-if="show" :style="{ display: show ? 'block' : 'none' }">
            <div :class="'modal-dialog modal-dialog-centered ' + modalsize" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ props.title }}</h5>
                        <a type="button" class="close" aria-label="Close" @click="close">
                            <i class="fas fa-times"></i>
                        </a>
                    </div>
                    <div class="modal-body">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
    import { defineProps, defineEmits, onMounted, onUnmounted } from 'vue';
    const emit = defineEmits(['close', 'confirm']);

    const props = defineProps({
        show: Boolean,
        title: {
            type: String,
            default: 'Confirmation',
        },
        modalsize: {
            type: String,
            default: 'modal-mg',
        },
    });
    const close = () => {
        emit('close');
    };

    const handleKeydown = (event) => {
        if (event.key === 'Escape') {
            close();
        }
    };

    onMounted(() => {
        window.addEventListener('keydown', handleKeydown);
    });

    onUnmounted(() => {
        window.removeEventListener('keydown', handleKeydown);
    });
</script>

