<template>
    <div class="d-flex">
        <button class="btn btn-sm btn-secondary me-2" @click.prevent="editItem()">
            <i class="fas fa-edit"></i>
        </button>
        <button v-if="candelete" class="btn btn-sm btn-danger" @click.prevent="deleteItem()">
            <i class="fa-solid fa-trash-can"></i>
        </button>
    </div>
</template>

<script setup>
    import { defineProps, defineEmits } from 'vue';
    const emit = defineEmits(['edit', 'delete']);

    const props = defineProps({
        item: {
            type: Object,
            default: null
        },
        candelete: {
            type: Boolean,
            default: true
        },
    });
    const editItem = () => {
        emit('edit', props.item);
    };
    const deleteItem = () => {
        emit('delete', props.item);
    };
</script>
