<template>
  <div class="container-fluid" style="margin-top: 40px">
    <h1>Welcome <span v-if="authUser">{{authUser?.firstName}} ({{authUser?.username}})</span> to our <span style="color: #0081cc">Application!</span></h1>
    <p>We're glad to have you here. Enjoy your stay!</p>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
  
const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
</script>

<style scoped>

</style>