import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}distributor-sales-offices`;

export const useDistributorSalesOfficesStore = defineStore({
    id: 'distributorSalesOffices',
    state: () => ({
        distributorSalesOffices: [],
        error: null
    }),
    actions: {
        async getAll() {
            this.distributorSalesOffices = [];
            try {
                this.distributorSalesOffices = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching distributor sales offices:', error)
                this.distributorSalesOffices = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching distributor sales office:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create distributor sales office:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update distributor sales office:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete distributor sales office:', error);
                throw parseError(error);
            }
        }
    }
});
