import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from "@/router";
import { createPinia } from 'pinia'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
// @ts-ignore
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

createApp(App)
    .use(router)
    .use(createPinia())
    .use(VueSidebarMenu)
    .component("v-select", vSelect)
    .mount('#app')
