import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}customer-types`;

export const useCustomerTypesStore = defineStore({
    id: 'customerTypes',
    state: () => ({
        customerTypes: []
    }),
    actions: {
        async getAll() {
            this.customerTypes = [];
            try {
                this.customerTypes = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching customer types:', error)
                this.customerTypes = {error};
            }
        }
    }
});
