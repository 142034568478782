import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}user-types`;

export const useUserTypesStore = defineStore({
    id: 'userTypes',
    state: () => ({
        userTypes: []
    }),
    actions: {
        async getAll() {
            this.userTypes = [];
            try {
                this.userTypes = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching User Types:', error)
                this.userTypes = {error};
            }
        }
    }
});
