<script setup lang="ts">

</script>

<template>
  <div class="container-fluid">
    <div>
      <h2 class="mt-4 mb-4 shimmer-effect"></h2>
      <div class="row">
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
      </div>
      <h4 class="shimmer-effect mt-4"></h4>
      <div class="row mt-4">
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
      </div>
      <h4 class="shimmer-effect mt-4"></h4>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mt-2 mb-2">
            <h5 class="card-title mt-2 mb-2 shimmer-effect"></h5>
            <p class="card-body shimmer-effect"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer-effect {
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: darkgray linear-gradient(90deg, #f0f0f0, #f7f7f7, #f0f0f0);
  background-size: 1000px 100%;
  position: relative;
}

.shimmer-effect::before {
  content: "\00a0"; /* Non-breaking space character */
}

body[data-bs-theme='dark'] .shimmer-effect {
  background: darkslategray linear-gradient(90deg, #2a2a2a, #333333, #2a2a2a);
}

.card {
  border: 0;
  height: 200px;
}
</style>