import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}customer-segments`;

export const useCustomerSegmentsStore = defineStore({
    id: 'customerSegments',
    state: () => ({
        customerSegments: []
    }),
    actions: {
        async getAll() {
            this.customerSegments = [];
            try {
                this.customerSegments = await fetchWrapper.get(`${baseUrl}/all`);
            } catch (error) {
                console.log('Error fetching countries:', error)
                this.customerSegments = {error};
            }
        }
    }
});
