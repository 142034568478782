import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useTabsStore } from '@/stores/tabs.store';
import router from "@/router";
import {useCustomersStore} from "@/stores/customers.store";
import {useProjectsStore} from "@/stores/projects.store";

const baseUrl = `${process.env.VUE_APP_API_URL}users`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: ''
    }),
    getters: {
        useTabs() {
            return this.user?.userSettings?.useTabs;
        },
        isDarkTheme() {
            return this.user?.userSettings?.isDarkTheme;
        },
        isSidebarOpen() {
            return this.user?.userSettings?.isSidebarOpen;
        }
    },
    actions: {
        async login(username, password) {
            const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            await router.push(this.returnUrl || '/');
            const storeTabs = useTabsStore();
            storeTabs.login();
        },
        logout() {
            const storeTabs = useTabsStore();
            storeTabs.logout();
            const storeProjects = useProjectsStore();
            storeProjects.logout();
            const storeCustomers = useCustomersStore();
            storeCustomers.logout();
            
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login').then(r => r);
        },
        setUserSettings(settings) {
            this.user.userSettings = settings;
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        microsoftLogin() {
            window.open(`${baseUrl}/microsoft-login`, '_self');
        },
        linkedinLogin() {
            window.open(`${baseUrl}/linkedin-login`, '_self');
        },
        async fetchUserProfile(jwtToken) {
            try {
                this.user = { token: jwtToken };
                const userProfile = await fetchWrapper.get(`${baseUrl}/profile`);
                this.user = {...userProfile, token: jwtToken};
                localStorage.setItem('user', JSON.stringify(userProfile));
                await router.push(this.returnUrl || '/');
            } catch (error) {
                console.error('Error fetching user profile:', error);
                await router.push('/login');
            }
        }
    }
});
