
const formatDate = (date: any) => {
    const cultureCode = 'de-DE';
    return date ? new Date(date).toLocaleDateString(cultureCode) : null;
}

const formatPercent = (value: number, decimaldigits: number) => {
    const cultureCode = 'de-DE';
    const decimalDigits = decimaldigits ?? 0;
    return value ? new Intl.NumberFormat(cultureCode, { style: 'percent', minimumFractionDigits: decimalDigits }).format(value) : null;
}

const formatNumber = (value: number) => {
    const cultureCode = 'de-DE';
    return value ? new Intl.NumberFormat(cultureCode).format(value) : null;
}

const formatMoney = (value: number, currencycode: string) => {
    const cultureCode =  'de-DE';
    const currencySymbol = currencycode !== '' ? currencycode : '';
    const options = {
        style: 'currency',
        currency: currencySymbol,
    };
    if (currencySymbol === '') {
        return value ? new Intl.NumberFormat(cultureCode, { minimumFractionDigits: 2 }).format(value) : null;
    }
    return value ? new Intl.NumberFormat(cultureCode, options).format(value) : null;
};


export {
    formatNumber,
    formatMoney,
    formatPercent,
    formatDate
}