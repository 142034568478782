import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}customer-sales`;

export const useCustomerSalesStore = defineStore({
    id: 'customerSales',
    state: () => ({
        customerSales: [],
        error: null
    }),
    actions: {
        async getForCustomer(customerId) {
            this.customerSales = [];
            try {
                this.customerSales = await fetchWrapper.get(`${baseUrl}/for-customer/${customerId}`);
            } catch (error) {
                console.log('Error fetching customer sales:', error)
                this.customerSales = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching customer sales:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create customer sales:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update customer sales:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete customer sales:', error);
                throw parseError(error);
            }
        }
    }
});
