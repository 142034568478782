import { defineStore } from 'pinia';
import router from "@/router";

export const useTabsStore = defineStore({
    id: 'tabs',
    state: () => ({
        tabs: []
    }),
    actions: {
        addTab(tab) {
            if (this.tabs.some(t => t.route === tab.route)) {
                return;
            }
            if (tab.route === '/profile') {
                return;
            }
            if (!tab.name) {
                if (tab.route === '/') {
                    tab.name = 'Home';
                } else if (tab.route === '/login') {
                    tab.name = 'Login';    
                } else {
                    const entity = tab.route?.split('/')[1];
                    const id = tab.route?.match(/[^/]+$/)[0];
                    if (entity === id) {
                        tab.name = `${entity.slice(0, -1)} list`;
                    } else {
                        tab.name = `${entity.slice(0, -1)}`;
                    }
                }
            }
            this.tabs.push(tab);
        },
        async removeTab(tab) {
            if (this.tabs.length === 1) {
                return;
            }
            this.tabs = this.tabs.filter(t => t.route !== tab.route);
            if (tab.route === router.currentRoute.value.path) {
                await router.push(this.tabs[this.tabs.length - 1].route);
            }
        },
        setTabNameByRoute(route, name) {
            const index = this.tabs.findIndex(t => t.route === route);
            if (index === -1) {
                return;
            }
            this.tabs[index].name = name;
        },
        logout() {
            this.tabs = [];
        },
        login() {
            this.tabs = [];
            this.addTab({ route: '/' });
        }
    }
});
