import {defineStore} from 'pinia';
import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}distributors`;

export const useDistributorsStore = defineStore({
    id: 'distributors',
    state: () => ({
        distributors: [],
        error: null
    }),
    actions: {
        async getAllWithDto() {
            try {
                const result = await fetchWrapper.get(`${baseUrl}/all/dto`);
                this.distributors = result.length > 0 ? result : [];
            } catch (error) {
                console.error('Error fetching distributors:', error);
                this.error = error;
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching distributor:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                const result = await fetchWrapper.post(`${baseUrl}`, params);
                this.distributor = result ? result : {};
            } catch (error) {
                this.error = error;
                console.error('Error create distributors:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update distributors:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete distributors:', error);
                throw parseError(error);
            }
        }
        //Validation
    }
});
