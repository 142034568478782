import {defineStore} from 'pinia';

import {fetchWrapper} from '@/helpers';
import {parseError} from "@/utils/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}customer-engineers`;

export const useCustomerEngineersStore = defineStore({
    id: 'customerEngineers',
    state: () => ({
        customerEngineers: [],
        error: null
    }),
    actions: {
        async getForCustomer(customerId) {
            this.customerEngineers = [];
            try {
                this.customerEngineers = await fetchWrapper.get(`${baseUrl}/for-customer/${customerId}`);
            } catch (error) {
                console.log('Error fetching customer engineers:', error)
                this.customerEngineers = {error};
            }
        },
        async getById(id) {
            try {
                return await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                console.error('Error fetching customer engineers:', error);
                throw parseError(error);
            }
        },
        async create(params) {
            try {
                return await fetchWrapper.post(`${baseUrl}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error create customer engineers:', error);
                throw parseError(error);
            }
        },
        async update(id, params) {
            try {
                return await fetchWrapper.put(`${baseUrl}/${id}`, params);
            } catch (error) {
                this.error = error;
                console.error('Error update customer engineers:', error);
                throw parseError(error);
            }
        },
        async delete(id) {
            try {
                return await fetchWrapper.delete(`${baseUrl}/${id}`);
            } catch (error) {
                this.error = error;
                console.error('Error delete customer engineers:', error);
                throw parseError(error);
            }
        }
    }
});
